<template>
  <div
    class="page_bg_grey blank-aside-js"
    :class="isOpenAside == 'true' ? 'blank-aside' : ''"
  >
    <div class="content-margin">
      <banner-section location="shaniv_cart-top"/>
      <div class="payment-content mt-15">
        <div v-if="paymentSummary" class="payment-main_container d-flex justify-content-between">
          <div class="products-container">
            <h2 class="fs21 mb-5 payment-container-title">
              {{ $t("Payment method") }}
            </h2>
            <div>
              <h4 class="mb-2">{{ $t("Select Credit-Card") }}</h4>
              <vue-select
                :options="selected_cc.options"
                class="input cus-select no-uppercase-select border-option-dropdown credit-dropdown"
                v-model="selected_cc.selected"
              ></vue-select>
            </div>
            <div v-if="selected_cc.selected.value === 0" class="payment-detail mt-7">
              <div class="d-flex justify-content-between align-items-center">
                <p class="m-0 text-uppercase"><b>{{ $t("Payment Detail") }}</b></p>
                <div class="d-flex payment-icon-group">
                  <img src="/assets/img/icons/mastercard-light-large.png" alt="" class="mx-2">
                  <img src="/assets/img/icons/visa.png" alt="" class="mx-2">
                </div>
              </div>
              <div class="d-flex justify-content-between mt-20">
                <div class="holder-name">
                  <v-text-field
                    v-model="new_cc_details.holder_name"
                    :rules="[rules.required]"
                    :label="$t('Cardholder Name')"
                  ></v-text-field>
                </div>
                <div class="holder-id">
                  <v-text-field
                    v-model="new_cc_details.holder_id"
                    :label="$t('Cardholder ID')"
                    :rules="[rules.required]"
                    v-mask="'#########'"
                  ></v-text-field>
                </div>
              </div>
              <div class="d-flex justify-content-between mt-10">
                <div class="card-number">
                  <v-text-field
                    v-model="new_cc_details.number"
                    :label="$t('Card Number')"
                    :rules="[rules.required]"
                    v-mask="'#### #### #### ####'"
                  ></v-text-field>
                </div>
                <div class="expiration">
                  <!-- <v-text-field
                    v-model="new_cc_details.number"
                    label="Expiration"
                  ></v-text-field> -->
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date"
                        :label="$t('Expiration')"
                        prepend-icon=""
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date"
                      type="month"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="menu = false"
                      >
                        {{ $t("Cancel") }}
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(date)"
                      >
                        {{ $t("Ok") }}
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>
                <div class="cvv">
                  <v-text-field
                    v-model="new_cc_details.cvv"
                    :label="$t('CVV')"
                    :rules="[rules.required]"
                    v-mask="'###'"
                  ></v-text-field>
                </div>
              </div>

            <div class="d-flex align-items-center justify-content-center">
              <v-checkbox 
                color="#0D3856" 
                class="cus-checkbox" 
                v-model="new_cc_details.store_cc"
                value="1"
                id="save_cc"
              >
              </v-checkbox>
              <label class="px-5" for="save_cc">{{ $t("Save Credit-Card information") }}</label>
            </div>

            <button 
              class="m-0-auto primary-btn checkout-btn"
              @click="payHandler"
            >
              {{ $t("Checkout") }}
            </button>
              <!-- <div class="cc_detail__item">
                <label class="label cc_detail__label">Expiration</label>
                <vue-select
                  :options="month_options"
                  style="width: 180px; display: inline-block"
                  class="input cus-select border-option-dropdown"
                  v-model="new_cc_details.month"
                ></vue-select>
                /
                <vue-select
                  :options="year_options"
                  style="width: 180px; display: inline-block"
                  class="input cus-select border-option-dropdown"
                  v-model="new_cc_details.year"
                ></vue-select>
              </div> -->
            </div>

            <!-- <div style="margin-top: 20px">
              <button
                class="
                  primary-btn primary-btn_sm
                  bg-orange
                  line-height1
                  m-0
                "
                @click="payHandler"
              >
                {{ $t('Pay') }}
              </button>
            </div> -->
          </div>
          <PaymentSummary></PaymentSummary>
        </div>
      </div>
    </div>
    <ShanivCustomerAgentModal></ShanivCustomerAgentModal>

    <loading
      :active.sync="createOrderLoading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import CartTable from '../../components/cart/CartTable';
import PaymentSummary from '../../components/cart/PaymentSummary';
import ShanivCustomerAgentModal from "../../components/ShanivCustomerAgentModal.vue";

import CartMixin from '../../utility/cart_mixin';
import BannerSection from "../../components/BannerSection";

export default {
  name: 'OrderPayment',
  mixins: [CartMixin],
  components: {
    BannerSection,
    CartTable,
    PaymentSummary,
    ShanivCustomerAgentModal
  },
  data() {
    return {
      date: new Date().toISOString().substr(0, 7),
      menu: false,
      modal: false,
      selected_cc: {
        options: [],
        selected: {},
        defaultOption: {
          label: this.$t('New Credit-Card'),
          value: 0
        }
      },
      new_cc_details: {
        number: '',
        holder_id: '',
        holder_name: '',
        cvv: '',
        store_cc: true
      },
      rules: {
        required: value => !!value || this.$t('Required')
      },
    };
  },
  computed: {
    ...mapState({
      createOrderLoading: (state) => state.cartSystem.createOrderLoading,
      paymentDetails: (state) => state.cartSystem.shanivPaymentDetails
    }),
    orderId() {
      return this.$route.params.id;
    },
    orderData() {
      let orderData = null;

      if (this.paymentDetails && this.paymentDetails.orderData) {
        orderData = {...this.paymentDetails.orderData};
      }

      return orderData;
    },
    orderedItems() {
      let orderedItems = [];

      if (this.orderData && this.orderData.ShanivOrderItems) {
        orderedItems = this.orderData.ShanivOrderItems.filter(
          soi => soi.BOOKNUM && (!soi.payment_required || soi.paid) && !soi.failed
        );
      }

      return orderedItems;
    },
    toPayItems() {
      let toPayItems = [];

      if (this.orderData && this.orderData.ShanivOrderItems) {
        toPayItems = this.orderData.ShanivOrderItems.filter(
          soi => !soi.BOOKNUM && soi.payment_required && !soi.paid && !soi.failed
        );
      }

      return toPayItems;
    },
    paymentSummary() {
      let paymentSummary = null;

      if (this.paymentDetails && this.paymentDetails.paymentSummary) {
        paymentSummary = {...this.paymentDetails.paymentSummary};
      }

      return paymentSummary;
    },
    dueDate() {
      let dueDate = null;

      if (this.paymentSummary && this.paymentSummary.dueDate) {
        dueDate = moment(this.paymentSummary.dueDate).format('DD/MM/YYYY dddd');
      } else {
        dueDate = 'N/A';
      }

      return dueDate;
    },
    PRICE() {
      let PRICE = 0;

      if (this.paymentSummary && this.paymentSummary.PRICE) {
        PRICE = this.paymentSummary.PRICE;
      }

      return PRICE;
    },
    VATPRICE() {
      let VATPRICE = 0;

      if (this.paymentSummary && this.paymentSummary.VATPRICE) {
        VATPRICE = this.paymentSummary.VATPRICE;
      }

      return VATPRICE;
    },
    DISCOUNT_PRICE() {
      let DISCOUNT_PRICE = 0;

      if (this.paymentSummary && this.paymentSummary.DISCOUNT_PRICE) {
        DISCOUNT_PRICE = this.paymentSummary.DISCOUNT_PRICE;
      }

      return DISCOUNT_PRICE;
    },
    DISCOUNT_VATPRICE() {
      let DISCOUNT_VATPRICE = 0;

      if (this.paymentSummary && this.paymentSummary.DISCOUNT_VATPRICE) {
        DISCOUNT_VATPRICE = this.paymentSummary.DISCOUNT_VATPRICE;
      }

      return DISCOUNT_VATPRICE;
    },
    DISCOUNT_AMOUNT() {
      let amount = 0;

      if (this.VATPRICE && this.DISCOUNT_VATPRICE) {
        amount = this.VATPRICE - this.DISCOUNT_VATPRICE;
      }

      amount = Math.round((amount + Number.EPSILON) * 100) / 100;
      return amount;
    },
    storedCreditCards() {
      let storedCreditCards = null;

      if (this.paymentDetails && this.paymentDetails.storedCreditCards) {
        storedCreditCards = [...this.paymentDetails.storedCreditCards];
      }

      return storedCreditCards;
    },
  },
  async created() {
      // load payment details
      await this.getPaymentDetails({id: this.orderId});
      this.generateStoredCCOptions();
  },
  methods: {
    ...mapActions('cartSystem', {
      getPaymentDetails: 'getPaymentDetails',
      payOrder: 'payOrder',
    }),
    openShanivCustomerAgentModal() {
      this.$modal.show('shanivCustomerAgentModal');
    },
    async payHandler() {
      // todo: add validation
      if (!this.selected_cc.selected.value
        && !(this.new_cc_details.number
          && this.new_cc_details.holder_id
          && this.new_cc_details.holder_name
          && this.new_cc_details.cvv
          && this.date)
      ) {
        alert('Error! Enter Card Details!');
        return false;
      }

      let paymentResult = await this.payOrder({
        id: this.orderId,
        data: {
          ...(this.selected_cc.selected.value && {cc_id: this.selected_cc.selected.value}),
          ...(!this.selected_cc.selected.value && {
            cc_number: this.new_cc_details.number,
            cc_holder_id: this.new_cc_details.holder_id,
            cc_holder_name: this.new_cc_details.holder_name,
            cc_cvv: this.new_cc_details.cvv,
            cc_validity: this.date,
            store_cc: !!this.new_cc_details.store_cc,
          }),
        }
      });

    },
    generateStoredCCOptions() {
      let options = [this.selected_cc.defaultOption];

      if (this.storedCreditCards
          && Array.isArray(this.storedCreditCards)
          && this.storedCreditCards.length > 0
      ) {
        this.storedCreditCards.forEach(scc => {
          options.push({
            label: `${scc.cc_type} - ...${scc.cc_last4}`,
            value: scc.id
          });
        });
      }

      this.selected_cc.options = options;
      this.selected_cc.selected = this.selected_cc.options[0];
    }
  }
};
</script>

<style lang="scss">
  .payment-detail {
    .v-label {
      transform-origin: top left;
    }
  }
  .v-menu__content {
    top: 400px !important;
  }
</style>

<style lang="scss" scoped>
.content-margin {
  margin: 10px;
}
.payment-content {
  padding-bottom: 50px;
  max-width: 950px;
  margin: 0 auto;
  .products-container {
    width: 50%;
    .credit-dropdown {
      border: none;
      border-radius: 8px;
      font-size: 18px;
      height: 46px;
    }
    .payment-detail {
      border-radius: 10px;
      background-color: #fff;
      padding: 20px 15px;
    }
    .payment-icon-group {
      img{
        width: 50px;
      }
    }
    .holder-name,
    .holder-id,
    .card-number {
      width: 46%;
    }
    .expiration {
      width: 30%;
    }
    .cvv {
      width: 8%;
    }
    .checkout-btn {
      max-width: 300px;
    }
  }
  @media (max-width: 899px) {
    margin-top: 10px !important;
    .payment-main_container {
      flex-direction: column-reverse;
      .products-container,
      .summary-container {
        width: 100%;
      }
      .products-container {
        border: 1px solid #707070;
        border-radius: 6px;
        padding: 10px;
        margin-top: 5px;
        .payment-container-title {
          text-align: center;
        }
      }
    }
  }
}
</style>